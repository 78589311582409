import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import {
  CloseIcon,
  ErrorIcon,
  RealtyTexasLogoIcon,
} from "../../constants/icons";
import { BaseInput } from "../../ui";
import { BaseButton } from "../../ui/Buttons";
import AlertMessage from "../AlertMessage/AlertMessage";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./forgotPassForm.css";
import "./responsive.css";
import { useScreenSize, UseValidateEmail } from "../../hooks";
import {getApiUrl} from "../../constants/methods";

const ForgotPassForm = ({
  container,
  isResetPass,
  setIsResetPass,
  setIsLoginForm,
}) => {
  const backHandler = () => {
    setIsResetPass(false);
    setIsLoginForm(true);
  };

  const closeHandler = () => {
    // setIsResetPass(false);
    container.setAttribute("visible", "false");
    setIsSuccessAlert(false);
  };

  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isSuccessAlertMessage, setIsSuccessAlertMessage] =
    useState("Base error text");
  const [isErrorAlertMessage, setIsErrorAlertMessage] =
    useState("Base error text");

  return (
    <>
      {isSuccessAlert && (
        <AlertMessage
          header="Success!"
          content={isSuccessAlertMessage}
          onClick={closeHandler}
        />
      )}
      {isErrorAlert && (
        <AlertMessage
          header="Error!"
          content={isErrorAlertMessage}
          onClick={() => {
            setIsErrorAlert(false);
          }}
        />
      )}
      {!isSuccessAlert && (
        <div className="forgot-pass-form-wrapper">
          <div className="forgot-pass-form-logo-wrapper">
            <RealtyTexasLogoIcon />
          </div>
          <div
            className="forgot-pass-form-close-icon-wrapper"
            onClick={closeHandler}
          >
            <CloseIcon />
          </div>
          <div className="forgot-pass-form-content-wrapper">
            <div className="forgot-pass-form-content-title">
              Forgot your password?
            </div>
            <ForgotPassContentForm
              setIsSuccessAlert={setIsSuccessAlert}
              setIsErrorAlert={setIsErrorAlert}
              setIsSuccessAlertMessage={setIsSuccessAlertMessage}
              setIsErrorAlertMessage={setIsErrorAlertMessage}
              setIsResetPass={setIsResetPass}
            />
            <div className="forgot-pass-form-alternative-block-back">
              <span onClick={backHandler}>Back to Log in</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassForm;

const ForgotPassContentForm = ({
  setIsErrorAlert,
  setIsErrorAlertMessage,
  setIsSuccessAlert,
  setIsSuccessAlertMessage,
  setIsResetPass,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorText, setCaptchaErrorText] = useState(false);
  const [chaptchaScale, setChaptchaScale] = useState(1.06);
  const [marginScale, setMarginScale] = useState(0);

  const checkErrors = () => {
    let result1 = false;
    let result2 = false;
    // valid email
    if (email === "") {
      setEmailError((prev) => true);
      setEmailErrorText("Required!");
      result1 = true;
    } else if (!UseValidateEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Should has email type!");
      result1 = true;
    } else {
      setEmailError((prev) => false);
    }
    //valid captcha
    if (captcha === null) {
      setCaptchaError((prev) => true);
      setCaptchaErrorText("Required!");
      result2 = true;
    } else {
      setCaptchaError((prev) => false);
    }
    if (!result1 && !result2) {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (isFirstSubmit) checkErrors();
  }, [email, captcha, isFirstSubmit]);

  const isSubmitConfirm = () => {
    return !checkErrors();
  };

  const successHandler = (data) => {
    setIsSuccessAlert(true);
    setIsSuccessAlertMessage(data);
  };

  const submitHandler = () => {
    setIsFirstSubmit(true);
    let isConfirm = isSubmitConfirm();
    if (isConfirm) {
      const dataObj = {
        Email: email,
        "g-recaptcha-response": captcha,
      };
      axios
        .post(
            getApiUrl("/api/v1/clients/reset-password"),
          dataObj,
          {
            headers: {
              Authorization: `Bearer ${captcha}`,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            successHandler(response.data.message);
          } else {
            setIsErrorAlert(true);
            setIsErrorAlertMessage(response.data.message);
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  };

  const recaptchaRef = React.useRef();

  function onChangeCaptcha(value) {
    setCaptcha(value);
  }

  const screenSize = useScreenSize();

  const getChaptchaScale = () => {
    if (screenSize[0] <= 360) {
      let x = (screenSize[0] - 40) / 304;
      setChaptchaScale(x);
    }
  };

  const getMarginScale = () => {
    if (screenSize[0] <= 360) {
      let x = (360 - screenSize[0]) * 0.225;
      setMarginScale(x);
    }
  };

  useEffect(() => {
    getChaptchaScale();
    getMarginScale();
  }, [screenSize[0]]);
  return (
    <form method="dialog" className="forgot-pass-form-content-form">
      <BaseInput
        dark
        placeholder="Email"
        className="forgot-pass-form-content-form-email"
        onChange={setEmail}
        value={email}
        borderColor={emailError ? colors.red : colors.gray}
        borderWeight={emailError ? "2px" : "1px"}
      />
      {emailError && (
        <div className="forgot-pass-content-form-input-error">
          <div>
            <ErrorIcon />
          </div>
          <div>
            <p>{emailErrorText}</p>
          </div>
        </div>
      )}
      <div className="login-form-content-form-input-wrapper">
        <ReCAPTCHA
          style={{
            transform:
              screenSize[0] >= 360 ? "scale(1.06)" : `scale(${chaptchaScale})`,
            transformOrigin: "0 0",
          }}
          ref={recaptchaRef}
          sitekey="6LdaE30jAAAAAEbA7bEuPajVMcMnsUb8wddFThI0"
          onChange={onChangeCaptcha}
          theme="dark"
        />
        {captchaError && (
          <div
            className="login-form-content-form-input-error-captcha"
            style={{
              top: screenSize[0] < 360 ? `${82 - marginScale}px` : "82px",
            }}
          >
            <div>
              <ErrorIcon />
            </div>
            <div>
              <p>{captchaErrorText}</p>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: screenSize[0] < 360 ? `-${marginScale}px` : 0 }}>
        <BaseButton bold height="40px" onClick={submitHandler}>
          Reset Password
        </BaseButton>
      </div>
    </form>
  );
};
